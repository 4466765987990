import {
  createContext,
  useContext,
  useMemo,
  useState,
  ReactElement,
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react';
import serverApi from '../Utils/api';
import { IMAGE_MAPPING } from '../Utils/constants/profileAvatars';

interface IPhoto {
  data: number[];
  type: string;
}

interface IUser {
  uuid: string;
  full_name?: string;
  email: string;
  token: string;
  role?: string;
  user_status?: string;
  stripe_id?: string;
  country: string;
  english_version?: string;
  email_verified?: boolean;
  photo?: IPhoto;
  subscription_type: string;
  plan_type: string;
  subscriptionValidUntil?: string;
}

export interface IAvatar {
  uuid: string;
  name: string;
  image: string;
}

export interface IProfile {
  uuid: string;
  profile_name?: string;
  age?: number;
  current_level?: number;
  full_name?: string;
  avatar_uuid: string;
  total_sessions?: number;
  no_of_mastered_cards: number;
  is_session_attended: boolean;
}

interface Auth {
  user: IUser;
  profiles: IProfile[];
  avatars: IAvatar[];
  selectedProfile: IProfile;
  error: string;
  isTutorial: boolean;
  isFirstLogin: boolean;
  sounds: boolean;
  restrictedAccess: boolean;
  updateSounds: (sounds: boolean) => void;
  updateRestrictedAccess: (restrictedAccess: boolean) => void;
  updateProfiles(profilesStore: IProfile[]): void;
  setCurrentProfile(profile: IProfile): void;
  setError: Dispatch<SetStateAction<string>>;
  removeIsTutorial(): void;
  removeIsFirstLogin(): void;
  login(userStore: IUser, profilesStore: IProfile[]): void;
  updateUser(userStore: IUser): void;
  signup(userStore: IUser): void;
  updateEmailVerified(emailVerified: boolean): void;
  updateFullname(fullname: string): void;
  logout(): void;
}

const AuthContext = createContext<Auth>({
  user: {
    uuid: '',
    token: '',
    email: '',
    country: '',
    subscription_type: '',
    plan_type: '',
  },
  selectedProfile: {
    uuid: '',
    profile_name: '',
    age: 0,
    current_level: 0,
    full_name: '',
    avatar_uuid: '',
    total_sessions: 0,
    no_of_mastered_cards: 0,
    is_session_attended: false,
  },
  profiles: [],
  avatars: [],
  error: '',
  isTutorial: false,
  isFirstLogin: false,
  sounds: true,
  restrictedAccess: true,
  updateSounds: () => void {},
  updateRestrictedAccess: () => void {},
  updateProfiles: () => void {},
  updateFullname: () => void {},
  setCurrentProfile: () => void {},
  setError: () => void {},
  removeIsTutorial: () => void {},
  removeIsFirstLogin: () => void {},
  login: () => void {},
  signup: () => void {},
  updateUser: () => void {},
  updateEmailVerified: () => void {},
  logout: () => void {},
});

export const AuthProvider = ({ children }: { children: ReactElement }) => {
  const [user, setUser] = useState<IUser>(
    localStorage.user ? JSON.parse(localStorage.user) : {}
  );
  const [profiles, setProfiles] = useState<IProfile[]>(
    localStorage.profiles ? JSON.parse(localStorage.profiles) : []
  );
  const [avatars, setAvatars] = useState<IAvatar[]>([]);

  const [selectedProfile, setSelectedProfile] = useState<IProfile>(
    localStorage.selectedProfile
      ? JSON.parse(localStorage.selectedProfile)
      : profiles[0]
  );

  const [error, setError] = useState<string>('');
  const [isTutorial, setIsTutorial] = useState<boolean>(
    localStorage.isTutorial ? JSON.parse(localStorage.isTutorial) : false
  );
  const [isFirstLogin, setIsFirstLogin] = useState<boolean>(
    localStorage.isFirstLogin ? JSON.parse(localStorage.isFirstLogin) : false
  );

  const [sounds, setSounds] = useState<boolean>(
    localStorage.sounds ? JSON.parse(localStorage.sounds) : true
  );
  const [restrictedAccess, setRestrictedAccess] = useState<boolean>(
    localStorage.restrictedAccess
      ? JSON.parse(localStorage.restrictedAccess)
      : true
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const {
          data: { avatars },
        } = await serverApi.getProfileAvatars();
        const avatarsArr: IAvatar[] = [];
        avatars.map((avatar: IAvatar) =>
          avatarsArr.push({
            uuid: avatar.uuid,
            image: IMAGE_MAPPING[avatar.name],
            name: avatar.name,
          })
        );
        setAvatars([...avatarsArr]);

        const user = localStorage.getItem('user');
        if (!user) {
          logout();
          return;
        }
      } catch (error) {
        console.error('Error while fetching user data', error);
        logout();
      }
    };

    fetchData();
  }, []);

  const login = (userStore: IUser, profilesStore: IProfile[]) => {
    localStorage.setItem('user', JSON.stringify(userStore));
    localStorage.setItem('profiles', JSON.stringify(profilesStore));
    localStorage.setItem('sounds', JSON.stringify(true));
    localStorage.setItem('restrictedAccess', JSON.stringify(true));
    setUser(userStore);
    setProfiles(profilesStore);
    if (profilesStore.length > 0) setCurrentProfile(profilesStore[0]);
    else setCurrentProfile({} as IProfile);
    setSounds(true);
    setRestrictedAccess(true);
  };

  const updateSounds = (sounds: boolean) => {
    localStorage.setItem('sounds', JSON.stringify(sounds));
    setSounds(sounds);
  };

  const updateRestrictedAccess = (restrictedAccess: boolean) => {
    localStorage.setItem('restrictedAccess', JSON.stringify(restrictedAccess));
    setRestrictedAccess(restrictedAccess);
  };

  const setCurrentProfile = (profile: IProfile) => {
    localStorage.setItem('selectedProfile', JSON.stringify(profile));
    setSelectedProfile(profile);
  };

  const signup = (userStore: IUser) => {
    localStorage.setItem('user', JSON.stringify(userStore));
    localStorage.setItem('isTutorial', JSON.stringify(true));
    localStorage.setItem('isFirstLogin', JSON.stringify(true));
    localStorage.setItem('sounds', JSON.stringify(true));
    localStorage.setItem('restrictedAccess', JSON.stringify(true));
    setUser(userStore);
    setIsTutorial(true);
    setIsFirstLogin(true);
    setSounds(true);
    setRestrictedAccess(true);
  };

  const removeIsTutorial = () => {
    localStorage.removeItem('isTutorial');
    setIsTutorial(false);
  };

  const removeIsFirstLogin = () => {
    setIsFirstLogin(false);
    localStorage.removeItem('isFirstLogin');
  };

  const updateUser = (userStore: IUser) => {
    localStorage.setItem('user', JSON.stringify(userStore));
    setUser(userStore);
  };

  const updateEmailVerified = (emailVerified: boolean) => {
    const userStore = { ...user, email_verified: emailVerified };
    localStorage.setItem('user', JSON.stringify(userStore));
    setUser(userStore);
  };

  const updateFullname = (fullname: string) => {
    const userStore = { ...user, full_name: fullname };
    localStorage.setItem('user', JSON.stringify(userStore));
    setUser(userStore);
  };

  const updateProfiles = (profilesStore: IProfile[]) => {
    localStorage.setItem('profiles', JSON.stringify(profilesStore));
    setProfiles(profilesStore);
  };

  const logout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('profiles');
    localStorage.removeItem('selectedProfile');
    localStorage.removeItem('sounds');
    localStorage.removeItem('restrictedAccess');
    setUser({
      uuid: '',
      token: '',
      email: '',
      country: '',
      subscription_type: '',
      plan_type: '',
    });
    setIsFirstLogin(false);
    setProfiles([]);
  };

  const memoedValue = useMemo(
    () => ({
      user,
      selectedProfile,
      profiles,
      avatars,
      error,
      isTutorial,
      isFirstLogin,
      sounds,
      restrictedAccess,
      updateSounds,
      updateRestrictedAccess,
      setError,
      removeIsTutorial,
      removeIsFirstLogin,
      setCurrentProfile,
      updateProfiles,
      login,
      signup,
      updateEmailVerified,
      updateFullname,
      logout,
      updateUser,
    }),
    [user, profiles, avatars, error, selectedProfile]
  );

  return (
    <AuthContext.Provider value={memoedValue}>{children}</AuthContext.Provider>
  );
};

export default function useAuth() {
  return useContext(AuthContext);
}
