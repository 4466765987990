import { useEffect, useState } from 'react';
import {
  CircularProgress,
  DialogTitle,
  Stack,
  IconButton,
} from '@mui/material';
import NoSleep from 'nosleep.js';
import Countdown from '../../Components/Countdown';
import SoundsSession from '../SoundsSession';
import { IFlashCard } from '../../Utils/constants/interfaces';
import {
  API_LIMIT,
  SESSION_STAGES,
  TOTAL_INCORRECT_WORDS_ALLOWED,
  TOTAL_SESSION_TIME,
} from '../../Utils/constants/speechSession';
import serverApi from '../../Utils/api';
import useAuth, { IProfile } from '../../Context/Auth';
import styles from './styles.module.scss';
import GreenCross from '../../Assets/Images/green-cross.svg';
import { useNavigate } from 'react-router-dom';
import PracticeSessionOne from '../PracticeSessionOne';
import PracticeSessionTwo from '../PracticeSessionTwo';
import PracticeSessionThree from '../PracticeSessionThree';
import randomArray from '../../Utils/common/randomArray';

interface SpeechSessionProps {
  currentStage: string;
  setCurrentStage: (stage: string) => void;
  isPractice?: boolean;
  onClose: () => void;
}

const SpeechSession = ({
  currentStage,
  setCurrentStage,
  isPractice,
  onClose,
}: SpeechSessionProps) => {
  const noSleep = new NoSleep();
  const {
    user,
    selectedProfile,
    updateProfiles,
    setCurrentProfile,
    removeIsFirstLogin,
  } = useAuth();
  const [totalWrong, setTotalWrong] = useState<number>(0);
  const [totalCorrect, setTotalCorrect] = useState<number>(0);
  const [wordsList, setWordsList] = useState<IFlashCard[]>([]);
  const [reviewSessionList, setReviewSessionList] = useState<IFlashCard[]>([]);
  const [incorrectList, setIncorrectList] = useState<IFlashCard[]>([]);
  const [apiCounter, setApiCounter] = useState<number>(0);
  const [timerValue, setTimerValue] = useState<number>(0);
  const [totalTimeForPracticeSession, setTotalTimeForPracticeSession] =
    useState<number>(0);
  const [sessionTimer, setSessionTimer] = useState<number>(0);

  const [sessionID, setSessionID] = useState<string>('');

  const navigate = useNavigate();

  const [masteryCards, setMasteryCards] = useState<IFlashCard[]>([]);
  const [randomCards, setRandomCards] = useState<IFlashCard[]>([]);
  const [incorrectCards, setIncorrectCards] = useState<IFlashCard[]>([]);
  const [practiceDataset, setPracticeDataset] = useState<IFlashCard[]>([]);

  // state to pause words for transitions between sessions
  const [isPaused, setIsPaused] = useState<boolean>(false);

  const STAGE_TO_FLASHCARD_LIST_MAPPING = {
    // Using ES6 computed property names feature to generate keys
    [SESSION_STAGES.words]: wordsList,
    [SESSION_STAGES.practiceOne]: practiceDataset,
    [SESSION_STAGES.review]: reviewSessionList,
    [SESSION_STAGES.practiceTwo]: practiceDataset, //to be changed
    [SESSION_STAGES.practiceThree]: practiceDataset, //to be changed
  };

  const sessionType = isPractice ? 'Practice' : 'Active';

  useEffect(() => {
    const createSession = async () => {
      try {
        const {
          data: { createdSession },
        } = await serverApi.createSession(
          sessionType,
          selectedProfile.uuid,
          user.token
        );
        setSessionID(createdSession.uuid);
      } catch (error) {
        console.error('Error while creating a session', error);
      }
    };
    createSession();
  }, []);

  useEffect(() => {
    noSleep.enable();

    return () => {
      noSleep.isEnabled && noSleep.disable();
    };
  }, []);

  useEffect(() => {
    if (apiCounter === API_LIMIT) {
      setCurrentStage(SESSION_STAGES.practiceCountdown);
      const time = TOTAL_SESSION_TIME * 1000;
      const total = (time - sessionTimer) / 3;
      setTotalTimeForPracticeSession(total);
      setTimerValue(0);
      getFlashCardsForPracticeSessions();
    }
  }, [apiCounter]);

  useEffect(() => {
    const getFlashCards = async () => {
      try {
        const {
          data: { flashcards },
        } = await serverApi.getFlashCards(
          user.token,
          user.uuid,
          selectedProfile.uuid
        );

        if (flashcards.reviewSessionList && flashcards.reviewSessionList.length)
          setReviewSessionList(flashcards.reviewSessionList);

        setWordsList([...flashcards]);
      } catch (error) {
        console.error('Error while fetching flashcards', error);
      }
    };
    isPractice ? getFlashCardsForPracticeSessions() : getFlashCards();
  }, []);

  const updateSessionState = (wrongCount = 0, correctCount = 0) => {
    // If the completeWords stage has ended, we set the totalWrong state so it can sent as prop to the sessionFinished stage.
    // The total wrong count will be updated just once after all the flashcards are attempted by the user.
    // Since the SoundsSession component is not re-mounted, the confirmWrongAttempt state in it never resets to 0.
    // Hence, it is carried forward even when the stage (sounds, separated words, etc.) is changed.
    if (currentStage === SESSION_STAGES.countdown) {
      if (isPractice) {
        setCurrentStage(SESSION_STAGES.practiceOne);
        const time = TOTAL_SESSION_TIME * 1000;
        const total = (time - sessionTimer) / 3;
        setTotalTimeForPracticeSession(total);
        setTimerValue(0);
      } else setCurrentStage(SESSION_STAGES.words);
    } else if (currentStage === SESSION_STAGES.practiceCountdown)
      setCurrentStage(SESSION_STAGES.practiceOne);
    else if (currentStage === SESSION_STAGES.words) {
      setTotalWrong(wrongCount);
      setTotalCorrect(correctCount);
    }
  };

  const updateSessionEnd = async () => {
    try {
      await serverApi.updateSessionByID(
        sessionID,
        sessionType,
        selectedProfile.uuid,
        user.token
      );
      const { data } = await serverApi.getProfiles(user.token);
      updateProfiles(data.profiles);
      setCurrentProfile(
        data.profiles.find(
          (profile: IProfile) => profile.uuid === selectedProfile.uuid
        )
      );
      removeIsFirstLogin();
    } catch (error) {
      console.error('Error while updating session', error);
    }
  };

  const onModalClose = () => {
    updateSessionEnd();
    onClose();
  };

  const sessionTimeEnded = async (wrongCount: number, correctCount: number) => {
    setTotalWrong(wrongCount);
    setTotalCorrect(correctCount);
    removeIsFirstLogin();
    setCurrentStage(SESSION_STAGES.finished);
    await updateSessionEnd();
  };

  const startReviewSession = () => {
    if (STAGE_TO_FLASHCARD_LIST_MAPPING[SESSION_STAGES.review].length > 0)
      setCurrentStage(SESSION_STAGES.review);
  };

  const updateIncorrectList = (incorrectWord: IFlashCard | null) => {
    if (incorrectWord === null) {
      setIncorrectList([]);
      return;
    }
    if (currentStage === SESSION_STAGES.words) {
      incorrectList.length <= TOTAL_INCORRECT_WORDS_ALLOWED &&
        setIncorrectList([...incorrectList, incorrectWord]);

      if (incorrectList.length + 1 === TOTAL_INCORRECT_WORDS_ALLOWED) {
        setCurrentStage(SESSION_STAGES.practiceCountdown);

        const time = TOTAL_SESSION_TIME * 1000;
        const total = (time - sessionTimer) / 3;
        setTotalTimeForPracticeSession(total);
        setTimerValue(0);
        getFlashCardsForPracticeSessions();
      }
    }
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (currentStage === SESSION_STAGES.practiceOne) {
      if (timerValue >= totalTimeForPracticeSession) {
        setIsPaused(true);
        timer = setTimeout(() => {
          setIsPaused(false);
          setCurrentStage(SESSION_STAGES.practiceTwo);
          setTimerValue(0);
        }, 5000);
      }
    } else if (currentStage === SESSION_STAGES.practiceTwo) {
      if (timerValue >= totalTimeForPracticeSession) {
        setIsPaused(true);
        timer = setTimeout(() => {
          setIsPaused(false);
          setCurrentStage(SESSION_STAGES.practiceThree);
          setTimerValue(0);
        }, 6000);
      }
    }
    return () => clearTimeout(timer);
  }, [timerValue]);

  useEffect(() => {
    if (timerValue >= totalTimeForPracticeSession) return;
    const intervalId = setInterval(() => {
      setTimerValue((t) => t + 100);
    }, 100);
    return () => clearInterval(intervalId);
  }, [currentStage, timerValue]);

  const getFlashCardsForPracticeSessions = async () => {
    try {
      const {
        data: { flashcards, masteryCards, incorrectCards },
      } = await serverApi.getFlashCardsForPractice(
        user.token,
        user.uuid,
        selectedProfile.uuid,
        sessionID
      );
      setIncorrectCards(incorrectCards);
      setMasteryCards(masteryCards);
      setRandomCards(flashcards);
    } catch (error) {
      console.error('Error while fetching practice session flashcards', error);
    }
  };

  const createDatasetForPracticeSessions = () => {
    const dataset: IFlashCard[] = [];
    switch (currentStage) {
      case SESSION_STAGES.practiceOne:
        dataset.push(...randomArray(incorrectCards, 4));
        dataset.push(...randomArray(masteryCards, 4 - dataset.length));
        dataset.push(...randomArray(randomCards, 4 - dataset.length));
        break;

      // select 3 words from incorrect words and 1 word from either mastery or random cards
      case SESSION_STAGES.practiceTwo:
      case SESSION_STAGES.practiceThree:
        dataset.push(...randomArray(incorrectCards, 3));
        dataset.push(...randomArray(masteryCards, 4 - dataset.length));
        dataset.push(...randomArray(randomCards, 4 - dataset.length));
        break;

      default:
        break;
    }
    setPracticeDataset(dataset);
  };

  useEffect(() => {
    createDatasetForPracticeSessions();
  }, [incorrectCards, masteryCards, randomCards, currentStage]);

  const renderSession = () => {
    switch (currentStage) {
      case SESSION_STAGES.countdown:
      case SESSION_STAGES.practiceCountdown:
        return (
          <Countdown
            count={3}
            onCountdownComplete={updateSessionState}
            sessionStage={currentStage}
          />
        );

      case SESSION_STAGES.finished:
        return isPractice
          ? onClose()
          : navigate('/session-finished', {
              state: {
                userLevel: selectedProfile.current_level || 1,
                totalCorrect,
                totalWords: totalWrong + totalCorrect,
                session_id: sessionID,
              },
            });

      case SESSION_STAGES.practiceOne:
        if (STAGE_TO_FLASHCARD_LIST_MAPPING[currentStage].length === 0) {
          return <CircularProgress className={styles.loader} color="success" />;
        }
        return (
          <PracticeSessionOne
            incorrectWordsList={practiceDataset}
            sessionStage={currentStage}
            reviewSessionCallback={startReviewSession}
            sessionEndedCallback={sessionTimeEnded}
            timerValue={sessionTimer}
            setTimerValue={setSessionTimer}
            isPaused={isPaused}
          />
        );
      case SESSION_STAGES.practiceTwo:
        if (STAGE_TO_FLASHCARD_LIST_MAPPING[currentStage].length === 0) {
          return <CircularProgress className={styles.loader} color="success" />;
        }
        return (
          <PracticeSessionTwo
            wordsList={practiceDataset}
            sessionStage={currentStage}
            reviewSessionCallback={startReviewSession}
            sessionEndedCallback={sessionTimeEnded}
            timerValue={sessionTimer}
            setTimerValue={setSessionTimer}
            isPaused={isPaused}
          />
        );
      case SESSION_STAGES.practiceThree:
        if (STAGE_TO_FLASHCARD_LIST_MAPPING[currentStage].length === 0) {
          return <CircularProgress className={styles.loader} color="success" />;
        }
        return (
          <PracticeSessionThree
            wordsList={practiceDataset}
            sessionStage={currentStage}
            reviewSessionCallback={startReviewSession}
            sessionEndedCallback={sessionTimeEnded}
            timerValue={sessionTimer}
            setTimerValue={setSessionTimer}
            isPaused={isPaused}
          />
        );
      case SESSION_STAGES.words:
        if (STAGE_TO_FLASHCARD_LIST_MAPPING[currentStage].length === 0) {
          return <CircularProgress className={styles.loader} color="success" />;
        }
        return (
          <SoundsSession
            sessionStage={currentStage}
            session_id={sessionID}
            flashCardList={STAGE_TO_FLASHCARD_LIST_MAPPING[currentStage]}
            onSessionComplete={updateSessionState}
            incorrectWordsList={incorrectList}
            updateIncorrectWords={updateIncorrectList}
            reviewSessionCallback={startReviewSession}
            sessionEndedCallback={sessionTimeEnded}
            setCurrentStage={setCurrentStage}
            setApiCounter={setApiCounter}
            apiCounter={apiCounter}
            timerValue={sessionTimer}
            setTimerValue={setSessionTimer}
          />
        );

      default:
        //TODO: Implement Page Not Found
        return <h2>Page Not Found</h2>;
    }
  };

  return (
    <>
      <Stack flexDirection="row-reverse" mt={2} mr={1}>
        {/* This button only needs to be on dev. */}
        {process.env.REACT_APP_SESSION_LOCK == 'false' &&
          [
            SESSION_STAGES.words,
            SESSION_STAGES.practiceOne,
            SESSION_STAGES.practiceTwo,
            SESSION_STAGES.practiceThree,
          ].includes(currentStage) && (
            <IconButton onClick={onModalClose}>
              <img src={GreenCross} alt="cross" />
            </IconButton>
          )}
      </Stack>
      <Stack alignItems="center">
        <DialogTitle className={`${styles['title']} ${styles[currentStage]}`}>
          {currentStage === SESSION_STAGES.words && "Let's Go!"}
          {currentStage === SESSION_STAGES.practiceOne && 'Practice 1'}
          {currentStage === SESSION_STAGES.practiceTwo && 'Practice 2'}
          {currentStage === SESSION_STAGES.practiceThree && 'Practice 3'}
        </DialogTitle>
      </Stack>
      {renderSession()}
    </>
  );
};

export default SpeechSession;
