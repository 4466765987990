export const RECORDER_STATES = {
  recording: 'recording',
  wrongAnswer: 'wrongAnswer',
  correctAnswer: 'correctAnswer',
};

export const SESSION_STAGES = {
  countdown: 'countdown',
  practiceCountdown: 'practice-countdown',
  words: 'words',
  practiceOne: 'practice_one',
  review: 'review-session',
  finished: 'session-finished',
  practiceTwo: 'practice_two',
  practiceThree: 'practice_three',
};

export const TOTAL_SESSION_TIME = 5.7 * 60; // number of seconds
export const TOTAL_INCORRECT_WORDS_ALLOWED = 4;
export const REVIEW_SESSION_TIME = 1 * 60; // number of seconds
export const VOICE_RECORDER_DURATION = 2; // number of seconds
export const NO_OF_LEVELS = 6;
export const CHALLENGE_NOW_CHANCES = 1;
export const API_LIMIT = 29;

export const WORD_OPACITY = {
  hidden: 'hidden',
  visible: 'visible',
};
