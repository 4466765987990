import { useEffect, useState } from 'react';
import {
  Box,
  Card,
  Button,
  Typography,
  Stack,
  CardContent,
  CardActions,
} from '@mui/material';
import SessionModal from '../SessionModal';
import styles from './styles.module.scss';
import Racoon from '../../Assets/Images/sessionCard_racoon.svg';
import Lock from '../../Assets/Images/lock.svg';
import useAuth from '../../Context/Auth';

const PracticeSessionCard = () => {
  const { isFirstLogin, user } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isMicAllowed, setIsMicAllowed] = useState<boolean>(false);
  const handleClose = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const checkMicPermission = () => {
      navigator.mediaDevices
        .getUserMedia({
          audio: true,
        })
        .then(() => {
          setIsMicAllowed(true);
        })
        .catch(() => {
          setIsMicAllowed(false);
        });
    };

    checkMicPermission();
  }, []);

  const isSessionLocked = isFirstLogin || user.user_status === 'deactivated';

  return (
    <Card className={styles['session-card']}>
      <CardContent>
        {isSessionLocked && (
          <Box component="img" src={Lock} className={styles['lock-image']} />
        )}
        <Stack alignItems="center" spacing={{ xs: 2, sm: 3, lg: 0 }}>
          <Typography variant="h3" className={styles['heading']}>
            <b>Practice</b>
          </Typography>
          <Box
            component="img"
            src={Racoon}
            className={styles['racoon-image']}
          />
          <Typography className={styles['subtitle']}>
            Ready to practise?
          </Typography>
        </Stack>
      </CardContent>
      <CardActions className={styles['session-action']}>
        <Button
          className={styles['session-button']}
          variant="contained"
          size="large"
          onClick={() => isMicAllowed && setIsModalOpen(true)}
          color="secondary"
          disabled={isSessionLocked}
        >
          Practise Again
        </Button>
      </CardActions>
      <SessionModal open={isModalOpen} onClose={handleClose} isPractice />
    </Card>
  );
};

export default PracticeSessionCard;
